<template>
  <button
    class="inline-flex items-center px-[20px] py-[14px] z-10"
    @click.prevent="$emit('click')"
  >
    <span class="w-[24px] inline-flex justify-center items-center">
      <slot name="icon"></slot>
    </span>
    <VExpandX :expand="active"><slot /></VExpandX>
  </button>
</template>

<script setup lang="ts">
import VExpandX from "./TransitionExpandWidth.vue";

defineProps<{
  active: boolean;
}>();

defineEmits<{
  (e: "click"): void;
}>();
</script>
