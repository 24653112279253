<template>
  <div class="mt-[91px] flex justify-center">
    <JSwitcher v-model="value" />
  </div>

  <div class="wireframe mt-[64px] flex justify-center overflow-hidden">
    <div class="relative w-full max-w-[1080px]">
      <div class="pb-[82.78%]"></div>

      <div v-html="wApp" class="absolute inset-0" />
      <div v-html="wTopBar" class="absolute inset-x-0 top-0" />
      <div
        v-html="wLogo"
        class="absolute left-[2.12962963%] top-[3.24384787%] w-[12.96296296%] transition-transform"
        :class="{
          '-translate-x-[49.28571429%]': state > 0,
        }"
      />
      <div
        v-html="wProfile"
        class="absolute left-[7.59259259%] top-[10.96196868%] w-[58.98148148%]"
      />
      <div
        v-html="wMenu"
        class="absolute left-[0.74074074%] top-[24.94407159%] w-[15.37037037%]"
      />
      <div
        v-html="wMenuIcons"
        class="absolute left-[1.57407407%] top-[28.95861298%] w-[1.11111111%] transition-transform"
        :class="{
          '-translate-x-[341.66666667%]': state > 0,
        }"
      />
      <div
        v-html="wYourSubscriptions"
        class="absolute left-[17.77777778%] top-[26.06263982%] w-[52.77777778%]"
      />
      <div
        class="absolute left-[17.77777778%] top-[64.20581655%] w-[52.77777778%] transition-transform"
        :class="{
          'translate-y-full': state > 0,
        }"
        v-html="wFreeShipping"
      ></div>
      <div
        class="absolute left-[17.77777778%] top-[70.91722595%] w-[52.77777778%] transition-transform"
        :class="{
          'translate-y-[33.75527426%]': state > 0,
        }"
        v-html="wAddToNextDelivery"
      ></div>
      <div
        class="absolute left-[72.22222222%] top-[14.54138702%] w-[27.03703704%] transition-transform backdrop-blur-[39px] rounded-[1.36986301%]"
        :class="{
          'translate-x-[18.49315068%] -translate-y-[15.43209877%]': state > 0,
        }"
        v-html="wRewardPoints"
      ></div>
      <div
        class="absolute left-[72.22222222%] top-[34.89932886%] w-[27.03703704%] transition-transform backdrop-blur-[39px] rounded-[1.36986301%]"
        :class="{
          'translate-x-[18.49315068%] translate-y-[20.27027027%]': state > 0,
        }"
      >
        <div v-html="wNextOrderBg" class="absolute inset-0" />
        <div v-html="wNextOrder" class="relative z-10" />
        <div
          class="absolute inset-0 border-[1px] rounded-[2.40963855%] border-dashed border-[#4200FF] transition-opacity"
          :class="{
            'opacity-0': state !== 1,
            'opacity-100': state === 1,
          }"
        />
        <div
          class="absolute inset-x-0 bottom-[104.08163265%] flex items-center text-[6.02409639%] text-[#3B66FF] transition-[opacity,transform]"
          :class="{
            'opacity-0 translate-y-full': state === 0,
            'opacity-100 translate-y-0': state > 0,
          }"
          v-html="wNextOrderHeader"
        />
        <div
          class="absolute inset-y-0 right-0 -left-[3.5%] flex items-center transition-opacity"
          :class="{
            'opacity-0': state < 2,
            'opacity-100': state > 1,
          }"
          v-html="wNextOrderSelect"
        />
      </div>
      <div
        class="absolute left-[72.22222222%] top-[53.80313199%] w-[27.03703704%] transition-transform backdrop-blur-[39px] rounded-[1.36986301%]"
        :class="{
          'translate-x-[18.49315068%] translate-y-[78.40909091%]': state > 0,
        }"
        v-html="wPaymentMethod"
      ></div>
      <div
        class="absolute left-[0.74074074%] top-[47.53914989%] w-[15.37037037%] transition-transform backdrop-blur-[39px] rounded-[2.40963855%]"
        :class="{
          '-translate-x-[32.53012048%]': state > 0,
        }"
      >
        <div v-html="wDiscountOffer" />
        <div
          class="absolute inset-0 border-[1px] rounded-[2.40963855%] border-dashed border-[#4200FF] transition-opacity"
          :class="{
            'opacity-0': state === 0,
            'opacity-100': state > 0,
          }"
        />
        <div
          class="absolute inset-x-0 bottom-[104.08163265%] flex items-center text-[6.02409639%] text-[#3B66FF] tranition-[opacity,transform]"
          :class="{
            'opacity-0 translate-y-full': state === 0,
            'opacity-100 translate-y-0': state > 0,
          }"
          v-html="wDiscountOfferHeader"
        />
      </div>

      <div
        class="absolute left-[0.74074074%] top-[63.98210291%] w-[15.37037037%] h-[2.23713647%] transition-[opacity,transform]"
        :class="{
          'opacity-0': state === 0,
          'opacity-100 -translate-x-[32.53012048%] translate-y-full': state > 0,
        }"
      >
        <WireframeTarget
          :active="menuOpened == 1"
          @click="
            menuOpened = 1;
            value = 'admin';
          "
        />
      </div>

      <div
        class="absolute left-[17.77777778%] top-[61.96868009%] w-[52.77777778%] h-[2.23713647%] transition-[opacity,transform]"
        :class="{
          'opacity-0': state === 0,
          'opacity-100 translate-y-full': state > 0,
        }"
      >
        <WireframeTarget
          :active="menuOpened == 2"
          @click="
            menuOpened = 2;
            value = 'admin';
          "
        />
      </div>
      <div
        class="absolute left-[17.77777778%] top-[68.68008949%] w-[52.77777778%] h-[2.23713647%] transition-[opacity,transform]"
        :class="{
          'opacity-0': state === 0,
          'opacity-100 translate-y-[300%]': state > 0,
        }"
      >
        <WireframeTarget
          :active="menuOpened == 3"
          @click="
            menuOpened = 3;
            value = 'admin';
          "
        />
      </div>
      <div
        class="absolute left-[72.22222222%] top-[32.66219239%] w-[27.03703704%] h-[2.23713647%] transition-[opacity,transform]"
        :class="{
          'opacity-0': state === 0,
          'opacity-100 translate-x-[18.49315068%] -translate-y-[25%]':
            state > 0,
        }"
      >
        <WireframeTarget
          :active="menuOpened == 4"
          @click="
            menuOpened = 4;
            value = 'admin';
          "
        />
      </div>
      <div
        class="absolute left-[72.22222222%] top-[51.51006711%] w-[27.03703704%] h-[2.23713647%] transition-[opacity,transform]"
        :class="{
          'opacity-0': state === 0,
          'opacity-100 translate-x-[18.49315068%] translate-y-[250%]':
            state > 0,
        }"
      >
        <WireframeTarget
          :active="menuOpened == 5"
          @click="
            menuOpened = 5;
            value = 'admin';
          "
        />
      </div>

      <div
        class="absolute left-[33.24814815%] top-[3.92024609%] w-[42.22222222%] transition-[opacity,transform] rounded-[1.75438596%] backdrop-blur-[29px]"
        :class="{
          'opacity-0': state < 2,
          'opacity-100': state > 1,
        }"
      >
        <div class="pb-[67.54385965%]" />
        <div
          class="absolute inset-y-0 left-0 -right-[2.19298246%]"
          v-html="wTooltip"
        />
      </div>

      <div
        v-if="state > 0 && menuOpened"
        class="absolute w-[18.33333333%] transition-[opacity,transform]"
        :class="{
          'left-[12.8722222226%] top-[30.591871791%]': menuOpened == 1,
          'left-[72.3222222226%] top-[28.591871791%]': menuOpened == 2,
          'left-[72.3222222226%] top-[39.791871791%]': menuOpened == 3,
          'left-[57.2222222226%] top-[10.4865771791%]': menuOpened == 4,
          'left-[57.2222222226%] top-[35.4865771791%]': menuOpened == 5,
        }"
        ref="menu"
      >
        <div class="pb-[201.53061224%]"></div>
        <div
          class="absolute inset-0 backdrop-blur-[39px] rounded-[5.04040404%]"
        ></div>
        <div
          class="absolute -inset-y-0"
          :class="{
            'left-0 -right-[9.5959596%]': menuOpened >= 4,
            '-left-[9.5959596%] right-0': menuOpened < 4,
          }"
          v-html="menuOpened >= 4 ? wComponentsBgMR : wComponentsBgBL"
        ></div>
        <div class="absolute inset-0" v-html="wComponents"></div>
      </div>

      <div
        class="absolute inset-x-[-1px] bottom-[-1px] pointer-events-none"
        v-html="wBottom"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import wApp from "../../assets/cp-wireframe/layers/App.svg?raw";
import wTopBar from "../../assets/cp-wireframe/layers/Top bar.svg?raw";
import wLogo from "../../assets/cp-wireframe/layers/Logo.svg?raw";
import wProfile from "../../assets/cp-wireframe/layers/Profile.svg?raw";
import wDiscountOffer from "../../assets/cp-wireframe/layers/Discount Offer.svg?raw";
import wDiscountOfferHeader from "../../assets/cp-wireframe/layers/Discount Offer - header.svg?raw";
import wMenu from "../../assets/cp-wireframe/layers/Menu.svg?raw";
import wMenuIcons from "../../assets/cp-wireframe/layers/Menu icons.svg?raw";
import wYourSubscriptions from "../../assets/cp-wireframe/layers/Your subscriptions.svg?raw";
import wFreeShipping from "../../assets/cp-wireframe/layers/freeshipping.svg?raw";
import wAddToNextDelivery from "../../assets/cp-wireframe/layers/Add to next delivery.svg?raw";
import wRewardPoints from "../../assets/cp-wireframe/layers/Reward points.svg?raw";
import wNextOrder from "../../assets/cp-wireframe/layers/Next order.svg?raw";
import wNextOrderBg from "../../assets/cp-wireframe/layers/Next order - Bg.svg?raw";
import wNextOrderHeader from "../../assets/cp-wireframe/layers/Next order - header.svg?raw";
import wNextOrderSelect from "../../assets/cp-wireframe/layers/Next order - select.svg?raw";
import wPaymentMethod from "../../assets/cp-wireframe/layers/Payment method.svg?raw";
import wTooltip from "../../assets/cp-wireframe/layers/tooltip.svg?raw";
import wComponents from "../../assets/cp-wireframe/layers/Components.svg?raw";
import wComponentsBgMR from "../../assets/cp-wireframe/layers/Components - Bg MR.svg?raw";
import wComponentsBgBL from "../../assets/cp-wireframe/layers/Components - Bg BL.svg?raw";
import wBottom from "../../assets/cp-wireframe/layers/Bottom.svg?raw";

import JSwitcher from "./JSwitcher.vue";
import WireframeTarget from "./WireframeTarget.vue";
import { computed } from "vue";
import { onClickOutside } from "@vueuse/core";

const menuOpened = ref<number | false>(false);
const menu = ref<HTMLDivElement | null>(null);

onClickOutside(menu, () => {
  menuOpened.value = false;
});

const value = ref<"customer" | "admin" | "developer">("customer");
const state = computed(() => {
  if (value.value === "customer") {
    return 0;
  }

  if (value.value === "admin") {
    return 1;
  }

  return 2;
});
</script>

<style scoped>
:deep([class*="transition-"]) {
  @apply duration-300;
}

.wireframe :deep(svg) {
  width: 100%;
  height: auto;
}

.wireframe :deep(svg [id^="Menu Item "]) {
  cursor: pointer;
}

.wireframe :deep(svg [id^="Menu Item "]:not(:hover) [id^="Menu Item Bg "]) {
  opacity: 0;
}
</style>
