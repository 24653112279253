<template>
  <transition
    name="expand"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <div v-show="expand" class="relative">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { ref } from "vue";

defineProps<{
  expand: boolean;
}>();

const transitioning = ref(false);

function onEnter(element: Element) {
  transitioning.value = true;
  const el = element as HTMLElement;
  el.style.width = "auto";
  el.style.opacity = "0";
  const { width } = getComputedStyle(el);
  el.style.width = "0";

  // Force repaint to make sure the
  // animation is triggered correctly.

  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(el).width;

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `width`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    el.style.width = width;
    el.style.opacity = "1";
  });
}

function onLeave(element: Element) {
  transitioning.value = true;
  const el = element as HTMLElement;
  const { width } = getComputedStyle(el);
  el.style.width = width;

  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(el).width;

  requestAnimationFrame(() => {
    el.style.width = "0";
    el.style.opacity = "0";
  });
}

function onAfterEnter(element: Element) {
  transitioning.value = false;
}

function onAfterLeave(element: Element) {
  transitioning.value = false;
}
</script>

<style scoped>
.expand-enter-active {
  @apply transition-[width,opacity] duration-300;
  overflow: hidden;
}
.expand-leave-active {
  @apply transition-[width,opacity] duration-300;
  overflow: hidden;
}
.expand-enter,
.expand-leave-to {
  width: 0;
  opacity: 0;
}
</style>
