<template>
  <div ref="root">
    <span class="relative flex-grow rotate-180">
      <hr />
      <hr
        class="absolute left-0 inset-y-0 transition-[width]"
        :class="{
          'w-0': !isActive,
          'w-full': isActive,
        }"
      />
    </span>
    <button
      ref="button"
      :data-active="active"
      :style="buttonStyles"
      @click="$emit('click')"
    >
      <svg
        ref="icon"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 6.00003L1 6.00003M6 6.00003L11 6.00003M6 6.00003L6 11.5M6 6.00003L6 0.5"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span ref="text">add component</span>
    </button>
    <span class="relative flex-grow">
      <hr />
      <hr
        class="absolute left-0 inset-y-0 transition-[width]"
        :class="{
          'w-0': !isActive,
          'w-full': isActive,
        }"
      />
    </span>
  </div>
</template>

<script lang="ts" setup>
import { useElementBounding, useElementHover } from "@vueuse/core";
import { computed } from "vue";
import { ref } from "vue";

defineEmits<{
  (e: "click"): void;
}>();

const props = defineProps<{
  active: boolean;
}>();

const root = ref<HTMLDivElement | null>(null);
const button = ref<HTMLButtonElement | null>(null);
const icon = ref<HTMLElement | null>(null);
const text = ref<HTMLSpanElement | null>(null);

const isHover = useElementHover(button);
const rootBounding = useElementBounding(root);
const iconBounding = useElementBounding(icon);
const textBounding = useElementBounding(text);

const isActive = computed(() => props.active || isHover.value);
const bounding = computed(() => (isActive.value ? textBounding : iconBounding));

const buttonStyles = computed(() => {
  return {
    fontSize: `${0.6 * rootBounding.height.value}px`,
    width: `${bounding.value.width.value}px`,
    height: `${bounding.value.height.value}px`,
  };
});
</script>

<style scoped>
div {
  @apply relative flex items-center h-full text-[#4200FF];
}

hr {
  @apply flex-grow h-[1px] border-none rotate-180;
  background-image: linear-gradient(to right, #3b3b3b, #4301ff);
}
hr:first-child {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 1'%3E%3Crect x='0' y='0' width='5' height='1' fill='black' /%3E%3C/svg%3E");
  mask-repeat: repeat;
}
hr + hr {
  background: #4200ff;
}

button {
  @apply border border-current rounded-full border-dashed h-full flex items-center justify-center;
  @apply transition-[width,height,color,background,border] box-content whitespace-nowrap flex-shrink-0;
  @apply font-medium;
}
button:hover,
button[data-active="true"] {
  @apply bg-[#4200FF] border-[#4200FF] text-white rounded-[0.3333333333em];
}

button > svg {
  @apply !w-[1.6666666667em] p-[0.333333em] flex-shrink-0;
}

button > span {
  @apply hidden px-[0.8333333333em] leading-[1.6666666667em];
}

button:hover > span,
button[data-active="true"] > span {
  display: block;
}
button:hover > svg,
button[data-active="true"] > svg {
  display: none;
}

svg {
  @apply !w-auto !h-auto;
}
</style>
