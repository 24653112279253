<template>
  <div class="p-[6px] border border-[#525354] rounded-[59px]" ref="root">
    <div class="relative flex">
      <div
        class="absolute inset-y-0 transition-[left,right] border border-dashed border-[#4200FF] rounded-[59px] pointer-events-none"
        :style="indicatorStyles"
      ></div>
      <JSwitcherItem
        ref="customer"
        :active="modelValue === 'customer'"
        @click="$emit('update:modelValue', 'customer')"
      >
        <template #icon>
          <svg
            class="mr-[7px]"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.899 13H4.24161C2.51935 13 1.15069 11.5532 1.24622 9.83359L1.68451 1.94453C1.71395 1.41461 2.15223 1 2.68297 1H13.4577C13.9884 1 14.4267 1.41461 14.4561 1.94453L14.8944 9.83359C14.9899 11.5532 13.6213 13 11.899 13Z"
              stroke="currentColor"
              stroke-width="1.43"
              stroke-linejoin="round"
            />
            <path
              d="M10.2703 1H5.87031C5.42848 1 5.07031 1.35817 5.07031 1.8V2C5.07031 3.65685 6.41346 5 8.07031 5C9.72717 5 11.0703 3.65685 11.0703 2V1.8C11.0703 1.35817 10.7121 1 10.2703 1Z"
              stroke="currentColor"
              stroke-width="1.43"
              stroke-linejoin="round"
            />
          </svg>
        </template>

        Customer
      </JSwitcherItem>

      <JSwitcherItem
        ref="admin"
        :active="modelValue === 'admin'"
        @click="$emit('update:modelValue', 'admin')"
      >
        <template #icon>
          <svg
            class="mr-[7px]"
            width="15"
            height="20"
            viewBox="0 0 15 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="7.94604"
              cy="4.57983"
              r="3.15112"
              stroke="currentColor"
              stroke-width="1.42857"
            />
            <path
              d="M3.75189 9.91211L4.12607 9.30367C3.86487 9.14304 3.53029 9.16704 3.2947 9.36331L3.75189 9.91211ZM12.1765 9.94431L12.6396 9.40043C12.4055 9.20113 12.0701 9.17398 11.807 9.33303L12.1765 9.94431ZM3.2947 9.36331C1.59294 10.781 0.928292 13.0847 0.928292 15.834H2.35686C2.35686 13.2633 2.9836 11.4818 4.20909 10.4609L3.2947 9.36331ZM0.928292 15.834C0.928292 16.4904 1.25845 17.0291 1.69701 17.4381C2.13061 17.8425 2.71199 18.1635 3.34873 18.416C4.62613 18.9225 6.28676 19.2161 7.91922 19.2746C9.54777 19.333 11.226 19.1604 12.527 18.6756C13.8001 18.2012 14.9613 17.3143 14.9613 15.834H13.5328C13.5328 16.3976 13.1184 16.9307 12.0282 17.3369C10.9659 17.7328 9.49299 17.9016 7.97042 17.847C6.45175 17.7925 4.96126 17.5186 3.87533 17.088C3.3304 16.8719 2.92705 16.6318 2.67134 16.3934C2.42059 16.1595 2.35686 15.9736 2.35686 15.834H0.928292ZM14.9613 15.834C14.9613 13.1087 14.3087 10.8215 12.6396 9.40043L11.7135 10.4882C12.9171 11.5129 13.5328 13.2859 13.5328 15.834H14.9613ZM11.807 9.33303C10.6936 10.0061 9.38847 10.3936 7.99061 10.3936V11.8221C9.65623 11.8221 11.216 11.3596 12.546 10.5556L11.807 9.33303ZM7.99061 10.3936C6.5726 10.3936 5.24991 9.99481 4.12607 9.30367L3.37772 10.5205C4.72018 11.3461 6.3009 11.8221 7.99061 11.8221V10.3936Z"
              fill="currentColor"
            />
            <path
              d="M7.94336 11.2573V14.8288"
              stroke="currentColor"
              stroke-width="1.42857"
              stroke-linecap="round"
            />
          </svg>
        </template>

        Admin
      </JSwitcherItem>

      <JSwitcherItem
        ref="developer"
        :active="modelValue === 'developer'"
        @click="$emit('update:modelValue', 'developer')"
      >
        <template #icon>
          <svg
            class="mr-[7px]"
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1038 11.8569L17.9609 8.99979L15.1038 6.14265"
              stroke="currentColor"
              stroke-width="1.42857"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
            <path
              d="M9.39062 16.1426L12.2478 1.85686"
              stroke="currentColor"
              stroke-width="1.42857"
              stroke-linecap="round"
            />
            <path
              d="M6.53292 11.8569L3.67578 8.99979L6.53293 6.14265"
              stroke="currentColor"
              stroke-width="1.42857"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
          </svg>
        </template>

        Developer
      </JSwitcherItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import JSwitcherItem from "./JSwitcherItem.vue";
import { useElementBounding } from "@vueuse/core";
import { computed } from "vue";

const props = defineProps<{
  modelValue: "customer" | "admin" | "developer";
}>();

defineEmits<{
  (e: "update:modelValue", value: "customer" | "admin" | "developer"): void;
}>();

const root = ref<HTMLElement | null>(null);
const customer = ref<HTMLElement | null>(null);
const admin = ref<HTMLElement | null>(null);
const developer = ref<HTMLElement | null>(null);

const rootBounding = useElementBounding(root);
const customerBounding = useElementBounding(customer);
const adminBounding = useElementBounding(admin);
const developerBounding = useElementBounding(developer);

const bounding = computed(() => {
  if (props.modelValue === "admin") {
    return adminBounding;
  }
  if (props.modelValue === "customer") {
    return customerBounding;
  }
  return developerBounding;
});

const indicatorStyles = computed(() => {
  // This is hardcoded because it's difficult to transition the indicator otherwise
  const iconSize = 24;
  const padding = 20;

  if (props.modelValue === "admin") {
    return {
      left: `${iconSize + padding * 2}px`,
      right: `${iconSize + padding * 2}px`,
    };
  }

  if (props.modelValue === "developer") {
    return {
      left: `${2 * iconSize + padding * 4}px`,
      right: 0,
    };
  }
  return {
    left: 0,
    right: `${2 * iconSize + padding * 4}px`,
  };
});
</script>
